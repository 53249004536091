.footer {
  padding: 32px 0;
  background: #E8E8E8;
  color: #666666;
  text-align: center;
  font-size: 12px !important;

  .item {
    a {
      color: #666666;
      text-decoration: none;
    }
  }

  .copyright {
    margin-top: 24px;
  }
}