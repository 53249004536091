.recruitment {
  padding-top: 36px;
  padding-bottom: 108px;

  .content + .content {
    margin-top: 80px;
  }
}

.recruitment-table-container {
  .name {
    white-space: nowrap;
  }
}