.appbar {
  box-shadow: none !important;
}

.navbar {
  background-color: #fff;
  color: #252525;

  &-brand {
    margin-right: auto;
    a {
      color: #252525;
      text-decoration: none;
      display: flex;
      align-items: center;

      .logo {
        width: 30px;
        height: 30px;
        margin-right: 3px;
      }
    }
  }

  .nav-item {
    > * + * {
      margin-left: 24px;
    }
  }
  .nav-link {
    text-decoration: none;
    color: #252525;
    font-size: 16px;
    padding-top: 26px;

    &.active {
      border-top: solid 3px #58A6F2;
    }
  }
}
.sidebar {
  width: 45vw;
  .item {
    a {
      color: #252525;
      text-decoration: none;
    }
  }
}