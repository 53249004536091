$breakpoints: (
  'sm': 'screen and (max-width: 400px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 1000px)',
  'xl': 'screen and (max-width: 1200px)',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

.br-sp {
  display: none;
  @include mq() {
    display: block;
  }
}

.top {
  position: relative;

  background-image: url(../img/home-bg-01.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%;

  &::before{
    /* 透過した黒を上から重ねるイメージ */
    background-color: rgba(0,0,0,0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: ' ';
  }

  .top-text {
    margin-top: 0;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    line-height: 200%;

    @include mq(sm) {
      font-size: 24px;
    }
  }
}

.service {
  text-align: center;
  padding: 96px 0 128px;

  &-title {
    text-align: center;
    color: #E0E0E0;
    font-size: 36px;
    margin-top: 0;
  }

  &-list {
    display: flex !important;
    flex-direction: row;
    justify-content: center;

    @include mq() {
      align-items: center;
      flex-direction: column;
    }
  }

  &-item + &-item {
    margin-left: 10px;

    @include mq() {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &-item {
    padding: 24px;
    max-width: 345px !important;
    width: 33.333%;
    transition: box-shadow 0.3s;

    &:hover {
       box-shadow: 0 0 20px #58A6F2;
    }

    @include mq() {
      width: 100%;
    }
    @include mq(sm) {
      max-width: 300px;
    }

    &_title {
      margin: 24px 0 !important;
    }

    &_text {
      text-align: left;
    }
  }
}

.accomplishment {
  position: relative;

  background-image: url(../img/home-bg-02.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%;

  &::before{
    /* 透過した黒を上から重ねるイメージ */
    background-color: rgba(0,0,0,0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: ' ';
  }

  &-list {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    width: 100%;

    > * + * {
      margin-left: 48px;

      @include mq() {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }
  &-item {
    &-number {
      font-size: 48px;
      font-weight: bold;
      line-height: 100%;
    }
    &-word {
      font-size: 24px;
      font-weight: bold;
      line-height: 100%;
    }
  }
}

.faq {
  padding: 96px 0;

  &-title {
    text-align: center;
    color: #E0E0E0;
    font-size: 36px;
    margin-top: 0;
  }

  .item {
    margin: 0 !important;
    padding: 24px;

    .title {
      p { font-weight: bold; }
    }
    .body {
      padding-bottom: 48px;
    }
  }

  .item + .item {
    margin-top: 8px;
  }
}

// 各ページトップ
.top-image {
  padding: 24px;
  width: 100%;
  height: 360px;
  object-fit: contain;
  animation-name: fadeUp;
  animation-duration: 2s;
  animation-iteration-count: 1;
  @include mq() {
    height: 300px !important;
  }
}
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}