@import './Home.scss';

.overview {
  padding-top: 36px;
  padding-bottom: 108px;

  .content + .content {
    margin-top: 80px;
  }
}

.history {
  .item-text-content {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 16px;
    }

    .date {
      width: 100px;
      flex-shrink: 0;
    }
    .text {
      width: calc(100% - 100px);
    }
  }
}

.overview-table-container {
  .name {
    white-space: nowrap;
  }
}